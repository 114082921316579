import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { BlockStack, Box, Page, Tabs, useBreakpoints } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import { useRedirect } from '../../hooks'
import AnalyticsSections from './screens/Sections'
import AnalyticsOrderAttribution from './screens/OrderAttribution'
import AnalyticsTopSelling from './screens/TopSelling'
import AnalyticsExperience from './screens/Experiences'
import StoreAnalytics from './screens/StoreAnalytics'
import DateTimeRangePopover from '../../components/DateTimeRangePopover'
import CheckoutAnalytics from './screens/Checkout'

function Analytics() {
  const { t } = useTranslation()
  const breakpoints = useBreakpoints()
  const searchParams = useSearchParams({
    start: new Date(
      Math.max(
        new Date('2024-12-10').getTime(),
        new Date().getTime() - 7 * 24 * 60 * 60 * 1000,
      ),
    ),
    end: new Date(new Date().setHours(23, 59, 59, 999)),
  })

  const durationFilter = {
    start: new Date(searchParams[0].get('start')),
    end: new Date(searchParams[0].get('end')),
  }

  // Id of a tab is mapped to its slug and corresponding component
  const TABS = [
    {
      content: t('Analytics.tabs.tab0.title'),
      id: 'store-analytics',
    },
    {
      content: t('Analytics.tabs.tab6.title'),
      id: 'checkout-analytics',
    },
    {
      content: t('Analytics.tabs.tab1.title'),
      id: 'section',
    },
  ]
  const { activeAnalyticsSection = null } = useParams()
  const { redirectToLink } = useRedirect()

  const [activeIndex, setActiveIndex] = React.useState(
    TABS.map(({ id }) => id).indexOf(activeAnalyticsSection) < 0
      ? 0
      : TABS.map(({ id }) => id).indexOf(activeAnalyticsSection),
  )

  const handleDurationFilterChange = (duration) => {
    searchParams[1](() => {
      return {
        start: duration.start.toISOString(),
        end: duration.end.toISOString(),
      }
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!activeAnalyticsSection) {
      redirectToLink({
        url: '/analytics/store-analytics?' + searchParams[0].toString(),
        external: false,
      })
    }
    const ind = TABS.map(({ id }) => id).indexOf(activeAnalyticsSection)
    setActiveIndex(ind < 0 ? 0 : ind)
  }, [activeAnalyticsSection])

  const ActiveComponent = SlugToComponentMap[activeAnalyticsSection]

  return (
    <Page
      fullWidth
      backAction={{
        url: '/',
      }}
      title={t('Analytics.title')}
      subtitle={t('Analytics.description')}
      primaryAction={
        <DateTimeRangePopover
          template="MMM dd, yyyy"
          disableDatesBefore={new Date('2024-12-09')}
          durationFilter={durationFilter}
          handleDurationFilterChange={handleDurationFilterChange}
        />
      }
    >
      <Box padding={breakpoints.smUp ? '0' : '400'}>
        <BlockStack gap={'200'}>
          <Tabs
            tabs={TABS}
            selected={activeIndex}
            onSelect={(idx) => {
              const tab = TABS[idx]
              redirectToLink({
                url: `/analytics/${tab.id}?` + searchParams[0].toString(),
                external: false,
              })
            }}
          />
          <Box padding={'200'}>
            {ActiveComponent && (
              <ActiveComponent durationFilter={durationFilter} />
            )}
          </Box>
          {/* {SlugToComponentMap[activeAnalyticsSection]()} */}
        </BlockStack>
      </Box>
      <Box minHeight="var(--p-space-300)" width="100%"></Box>
    </Page>
  )
}

export default Analytics

const SlugToComponentMap = {
  section: AnalyticsSections,
  'order-attribution': AnalyticsOrderAttribution,
  'top-selling': AnalyticsTopSelling,
  // 'post-checkout': AnalyticsPostCheckout,
  experience: AnalyticsExperience,
  'store-analytics': StoreAnalytics,
  'checkout-analytics': CheckoutAnalytics,
}
