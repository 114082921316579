import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  IndexFilters,
  IndexFiltersMode,
  IndexTable,
  InlineGrid,
  InlineStack,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
  useSetIndexFiltersMode,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useEffect, useMemo, useState } from 'react'

import { useShopDetails } from '../../../hooks/useShopDetails'
import { numberFormater, percentageFormatter } from '../../../utils/formater'
import { getSectionsStats } from '../../../apis/analytics'
import { useRedirect } from '../../../hooks'

const MAX_SECTIONS_PER_PAGE = 5

export function SectionsCard() {
  const { t } = useTranslation()
  const [activeExperience, setActiveExperience] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [query, setQuery] = useState('')
  const { redirectToLink } = useRedirect()
  const shopQuery = useShopDetails()
  const statsQuery = useQuery({
    queryKey: ['sections'],
    queryFn: async () => {
      const endDate = new Date()
      const sectionIds = shopQuery.data.sections.map((section) => section.id)
      const startDate = new Date(endDate.valueOf() - 1000 * 60 * 60 * 24) // 24 hours
      const payload = {
        dateGte: startDate.toISOString().split('T')[0],
        dateLte: endDate.toISOString().split('T')[0],
        sections: sectionIds,
      }
      const { data, error } = await getSectionsStats(payload)
      if (error) {
        return Promise.reject(error)
      }
      return data.stats
    },
    enabled: shopQuery.data?.sections?.length > 0,
  })
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Default)

  const experiences = useMemo(() => {
    if (!shopQuery.data) return []
    return shopQuery.data.experiences
  }, [shopQuery.data])

  const rowMarkup = useMemo(() => {
    if (!shopQuery.data || !statsQuery.data) return []
    const sectionStatsArray = statsQuery.data || []
    const sections = shopQuery.data.sections
      .filter((sec) => sec.experience == experiences[activeExperience].id)
      .filter((sec) => sec.title.toLowerCase().includes(query.toLowerCase()))
      .slice(
        MAX_SECTIONS_PER_PAGE * (activePage - 1),
        MAX_SECTIONS_PER_PAGE * activePage,
      )
    return sections.map((section, index) => {
      const sectionStats = sectionStatsArray.find(
        (stat) => stat.section == section.id,
      )
      return (
        <IndexTable.Row
          id={section.id}
          key={section.id}
          onClick={() => {
            redirectToLink({
              url: '/sections/' + section.id,
            })
          }}
          position={index}
        >
          <IndexTable.Cell key="section">
            <BlockStack gap={'100'}>
              <Text variant="bodyMd" fontWeight="bold" as="span">
                {section.title}
              </Text>
              <Text variant="bodyMd" fontWeight="bold" as="span">
                ID: {section.id}
              </Text>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell key={'type'}>
            {t(`Widgets.${section.type}.label`)}
          </IndexTable.Cell>
          <IndexTable.Cell key={'page'}>
            {(() => {
              const page = shopQuery.data.pages.find(
                (page) => page.id === section.page,
              )
              return t(`Pages.${page.type}.label`)
            })()}
          </IndexTable.Cell>
          <IndexTable.Cell key={'stats'}>
            <InlineStack gap="300">
              <InlineStack gap="100">
                <Text>CTR:</Text>
                <Text fontWeight="semibold">
                  {percentageFormatter(sectionStats?.interactionRate || 0)}
                </Text>
              </InlineStack>
            </InlineStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Badge
              progress={'complete'}
              tone={section.enabled ? 'success' : 'critical'}
            >
              {t(`SectionsCard.status.${section.enabled.toString()}`)}
            </Badge>
          </IndexTable.Cell>
        </IndexTable.Row>
      )
    })
  }, [
    statsQuery.data,
    shopQuery.data,
    activeExperience,
    experiences,
    activePage,
    query,
  ])

  const totalSections = shopQuery.data?.sections?.filter(
    (sec) => sec.experience == experiences[activeExperience].id,
  ).length
  const isLoading = shopQuery.isLoading || statsQuery.isLoading

  useEffect(() => {
    // Reset page number when query changes
    setActivePage(1)
  }, [query])

  return (
    <Card padding={'0'}>
      <Box padding={'400'}>
        <InlineGrid alignItems="center" columns={'1fr auto'} gap={'300'}>
          <Text variant="headingMd">{t('SectionsCard.title')}</Text>
          <Button size="micro" url="/sections">
            {t('SectionsCard.cta')}
          </Button>
        </InlineGrid>
      </Box>
      <Divider />
      <IndexFilters
        tabs={experiences.map((exp, idx) => ({
          content: exp.name,
          index: idx,
          onAction: () => {},
          id: exp.id + 'exp',
          actions: [],
          isLocked: false,
          value: exp.id,
        }))}
        selected={activeExperience}
        onSelect={setActiveExperience}
        canCreateNewView={false}
        filters={[]}
        appliedFilters={[]}
        mode={mode}
        setMode={setMode}
        queryValue={query}
        onQueryChange={setQuery}
        onQueryClear={() => {
          setQuery('')
        }}
        queryPlaceholder={t('SectionsCard.searchPlaceholder')}
        loading={isLoading}
      />
      {isLoading ? (
        getSkeletonRows()
      ) : (
        <IndexTable
          resourceName={{
            singular: t('SectionsCard.section'),
            plural: t('SectionsCard.sections'),
          }}
          itemCount={rowMarkup.length}
          emptyState={
            !isAnyFilterApplied() && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text variant="headingMd" as="h6" tone="base">
                  {t('Section.List.Table.emptyState.title')}
                </Text>
                <Text variant="bodySm" as="p">
                  {t('Section.List.Table.emptyState.subtitle')}
                </Text>
                <Button
                  variant="primary"
                  url={`/sections/create${experiences[activeExperience] ? `?experienceId=${experiences[activeExperience].id}` : ''}`}
                >
                  {t('Section.List.Table.emptyState.cta')}
                </Button>
              </div>
            )
          }
          selectable={false}
          headings={[
            {
              title: t('SectionsCard.section'),
              id: 'section',
            },
            {
              title: t('SectionsCard.type'),
              id: 'type',
            },
            {
              title: t('SectionsCard.page'),
              id: 'page',
            },
            {
              title: t('SectionsCard.stats'),
              id: 'stats',
            },
            {
              title: t('SectionsCard.status.title'),
              id: 'status',
            },
          ]}
          fullWidth
          pagination={{
            hasPrevious: activePage > 1,
            hasNext: MAX_SECTIONS_PER_PAGE * activePage < totalSections,
            onPrevious: () => setActivePage(activePage - 1),
            onNext: () => setActivePage(activePage + 1),
          }}
        >
          {rowMarkup}
        </IndexTable>
      )}
    </Card>
  )

  function getSkeletonRows() {
    const rows = Array.from({ length: 5 }).map((_, index) => (
      <IndexTable.Row id={index} key={index} position={index}>
        <IndexTable.Cell key="section">
          <SkeletonDisplayText size="small" />
        </IndexTable.Cell>
        <IndexTable.Cell key={'type'}>
          <SkeletonDisplayText size="small" />
        </IndexTable.Cell>
        <IndexTable.Cell key={'page'}>
          <SkeletonDisplayText size="small" />
        </IndexTable.Cell>
        <IndexTable.Cell key={'stats'}>
          <SkeletonDisplayText size="small" />
        </IndexTable.Cell>
        <IndexTable.Cell key={'stats'}>
          <SkeletonDisplayText size="small" />
        </IndexTable.Cell>
      </IndexTable.Row>
    ))

    return (
      <IndexTable
        resourceName={{
          singular: t('SectionsCard.section'),
          plural: t('SectionsCard.sections'),
        }}
        itemCount={rows.length}
        selectable={false}
        headings={[
          {
            title: t('SectionsCard.section'),
            id: 'section',
          },
          {
            title: t('SectionsCard.type'),
            id: 'type',
          },
          {
            title: t('SectionsCard.page'),
            id: 'page',
          },
          {
            title: t('SectionsCard.stats'),
            id: 'stats',
          },
          {
            title: t('SectionsCard.status.title'),
            id: 'status',
          },
        ]}
        fullWidth
      >
        {rows}
      </IndexTable>
    )
  }

  function isAnyFilterApplied() {
    return query.length > 0
  }
}
