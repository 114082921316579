import React, { useState } from 'react'
import {
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  InlineGrid,
  InlineStack,
  Layout,
  Link,
  Text,
  Tooltip,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { ClipboardCheckFilledIcon, ClipboardIcon } from '@shopify/polaris-icons'

import { openChat } from '../../../../components/ExternalScripts'
import { areFeaturesEnabled } from '../../../../utils/features'
import { FEATURES } from '../../../../constants/features'

function DesignSection({
  layout,
  template,
  onClick,
  widgetPreview,
  sectionId,
  shopConfig,
  shopPlan,
  widget
}) {
  const { t } = useTranslation(), type = widget.type, templateId = widget.template
  const content = `<div id='rk-widget-${sectionId}' class='rk-${type}'></div>`
  const [copied, setCopied] = useState(false)

  const templateEnabled = shopConfig.templatesEnabled
  const isLiveChatEnabled = areFeaturesEnabled([FEATURES.LIVE_SUPPORT], {
    shopPlan,
  })

  return (
    <Layout.AnnotatedSection
      title={t('Section.Details.DesignDetails.title')}
      description={t('Section.Details.DesignDetails.description')}
    >
      {templateEnabled ? (
        <BlockStack gap={'300'}>
          <Card>
            <BlockStack gap="200">
              <InlineGrid columns="1fr auto">
                <InlineStack gap={'1000'} blockAlign="stretch">
                  <div>
                    <Text as="h4" variant="bodyMd">
                      {t(
                        'Section.Details.DesignDetails.FormFields.template.label',
                      )}
                    </Text>
                    <Text variant="headingMd" as="strong">
                      {template}
                    </Text>
                  </div>
                  <div>
                    <Text as="h4" variant="bodyMd">
                      {t(
                        'Section.Details.DesignDetails.FormFields.layout.label',
                      )}
                    </Text>
                    <Text variant="headingMd" as="strong">
                      {layout}
                    </Text>
                  </div>
                </InlineStack>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 'var(--p-space-200)',
                  }}
                >
                  <Button
                    size="slim"
                    url={`/settings/templates/${templateId}/configure`}
                  >
                    {t('Section.Details.DesignDetails.updateTemplateCta')}
                  </Button>
                  <Button variant="primary" size="slim" onClick={onClick}>
                    {t('Section.Details.DesignDetails.FormFields.btnText')}
                  </Button>
                </div>
              </InlineGrid>
              {widgetPreview}
            </BlockStack>
          </Card>
          <Card>
            <BlockStack gap={'200'}>
              <Text as="strong">
                {t('Section.Details.DesignDetails.CustomAlignment.title')}
              </Text>
              <InlineGrid columns={'1fr auto'} gap={'200'}>
                <Box background="bg-fill-active" padding={'100'}>
                  <code>{content}</code>
                </Box>
                <div>
                  <Tooltip
                    content={
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {copied
                          ? t(
                              'Section.Details.DesignDetails.CustomAlignment.doneCta',
                            )
                          : t(
                              'Section.Details.DesignDetails.CustomAlignment.cta',
                            )}
                      </span>
                    }
                  >
                    <Button
                      icon={copied ? ClipboardCheckFilledIcon : ClipboardIcon}
                      variant="secondary"
                      onClick={() => {
                        navigator.clipboard.writeText(String(content))
                        setCopied(true)
                        setTimeout(() => {
                          setCopied(false)
                        }, 3000)
                      }}
                    />
                  </Tooltip>
                </div>
              </InlineGrid>
            </BlockStack>
          </Card>
        </BlockStack>
      ) : (
        <Card>
          <Banner
            action={{
              content: t('DefaultText.contactSupport'),
              onAction: isLiveChatEnabled ? openChat : undefined,
              url: isLiveChatEnabled ? undefined : 'mailto:support@glood.ai',
            }}
          >
            <Text>{t('DefaultText.oldTemplateNotEnabled')}</Text>
          </Banner>
        </Card>
      )}
    </Layout.AnnotatedSection>
  )
}

export default DesignSection
