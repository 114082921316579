import { Routes as ReactRouterRoutes, Route } from 'react-router-dom'
import { useEffect } from 'react'

import HomePage from './container/Homepage'
import Plans from './pages/plans'
import Sections from './pages/sections'
import VisualEditor from './pages/visual-editor'
import SectionCreate from './pages/section-create'
import SectionDetails from './container/Section/Detail'
import EditSection from './container/Section/Edit'
import ProductRecommendation, {
  NewProductRecommendation,
} from './container/Recommendation/ProductRecommendation'
import GlobalRecommendation from './container/Recommendation/GlobalRecommendation'
import RuleBasedRecommendation from './container/Recommendation/RuleBasedRecommendation'
import ProductRecommendationEdit from './container/Recommendation/ProductRecommendation/Details'
import RecommendationDataset from './container/Recommendation/Dataset'
import Templates from './container/Templates/Details'
import CreateTemplate from './container/Templates/Create'
import ConfigureTemplate from './container/Templates/Configure'
import Analytics from './container/Analytics'
import Settings from './container/Settings'
import ABExperience from './container/ABExperience'
import CreateABExperience from './container/ABExperience/Create'
import Onboarding from './container/Onboarding'
import CheckoutUpsell from './container/CheckoutUpsell'
import CampaignCreate from './container/CheckoutUpsell/Campaign/Create'
import CampaignDetails from './container/CheckoutUpsell/Campaign/Detail'
import CheckoutUpsellConfig from './container/CheckoutUpsell/Config'
import NotFound from './pages/NotFound'
import {
  UpsertBundlesScreen,
  CreateBundle,
  BundlesList,
} from './container/Bundles'
import ErrorScreen from './container/Error'
import PlanPage from './pages/plans'
import { Recommendation } from './container/Recommendation'
import { PostCheckoutEditor } from './container/CheckoutUpsell/Campaign/PostCheckoutEditor'
import { useRedirect } from './hooks'

export default function Routes() {
  let shouldRedirect = false
  const { redirectToLink } = useRedirect()

  if (window.shopify && window.shopify.data && window.shopify.data.onboarding) {
    shouldRedirect = window.shopify.data.onboarding.redirectToOnboarding
  }

  useEffect(() => {
    if (shouldRedirect) {
      redirectToLink({
        url: '/onboarding',
        external: false,
      })
    }
  }, [shouldRedirect])

  return (
    <ReactRouterRoutes>
      <Route path="/error" element={<ErrorScreen />} />
      <Route path="/" element={<HomePage />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/sections" element={<Sections />} />
      <Route path="/visual-editor" element={<VisualEditor />} />
      <Route path="/recommendation" element={<Recommendation />} />
      <Route path="/sections/create" element={<SectionCreate />} />
      <Route path="/sections/:sectionId" element={<SectionDetails />} />
      <Route path="/sections/:sectionId/edit" element={<EditSection />} />
      <Route
        path="/settings/recommendation/:widgetType/product"
        element={<ProductRecommendation />}
      />
      <Route
        path="/settings/recommendation/:widgetType/global"
        element={<GlobalRecommendation />}
      />
      <Route
        path="/settings/recommendation/:widgetType/rule"
        element={<RuleBasedRecommendation />}
      />
      <Route
        path="/settings/recommendation/:widgetType/product/new"
        element={<NewProductRecommendation />}
      />
      <Route
        path="/settings/recommendation/:widgetType/product/:productId"
        element={<ProductRecommendationEdit />}
      />
      <Route
        path="/settings/recommendation/dataset/:datasetId"
        element={<RecommendationDataset />}
      />
      <Route path="/settings/templates" element={<Templates />} />
      <Route path="/settings/templates/create" element={<CreateTemplate />} />
      <Route
        path="/settings/templates/:templateId/configure"
        element={<ConfigureTemplate />}
      />
      <Route path="/analytics" element={<Analytics />} />
      <Route path="/analytics/:activeAnalyticsSection" element={<Analytics />} />
      <Route path="/bundles" element={<BundlesList />} />
      <Route path="/bundles/create/:bundleType" element={<CreateBundle />} />
      <Route path="/bundles/:bundleId" element={<UpsertBundlesScreen />} />
      <Route path="/pricing" element={<PlanPage />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/experience" element={<ABExperience />} />
      <Route path="/experience/create" element={<CreateABExperience />} />
      <Route path="/onboarding" element={<Onboarding />} />
      <Route path="/checkout-upsell" element={<CheckoutUpsell />} />
      <Route path="/checkout-upsell/create" element={<CampaignCreate />} />
      <Route path="/checkout-upsell/configure" element={<CheckoutUpsellConfig />} />
      <Route path="/checkout-upsell/:campaignId" element={<CampaignDetails />} />
      <Route
        path="/checkout-upsell/post-checkout/edit/:campaignId"
        element={<PostCheckoutEditor />}
      />
      <Route path="*" element={<NotFound />} />
    </ReactRouterRoutes>
  )
}
