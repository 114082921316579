import React, { useEffect } from 'react'
import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Avatar,
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  ChoiceList,
  Filters,
  Grid,
  Icon,
  IndexTable,
  InlineGrid,
  InlineStack,
  Layout,
  Page,
  Popover,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Text,
  TextField,
  Tooltip,
  useBreakpoints,
} from '@shopify/polaris'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardCheckFilledIcon,
  ClipboardIcon,
  DeleteIcon,
  EditIcon,
  LockIcon,
  MenuVerticalIcon,
  PlusIcon,
  SettingsIcon,
  StatusIcon,
} from '@shopify/polaris-icons'
import { useQuery } from 'react-query'

import { onboardingSectionImage } from '../assets/index.js'
import { useRedirect } from '../hooks/useRedirect.js'
import StopPropogation from '../components/StopPropogation/index.jsx'
import { PAGE_TYPES } from '../constants/page.js'
import { useShopDetails } from '../hooks/useShopDetails.js'
import { ExperienceStatuses } from '../constants/enums.js'
import * as Recommendations from '../constants/widgets'
import queryClient from '../utils/query'
import { deleteExperience, updateExperience } from '../apis/experience.js'
import CustomModal from '../components/CustomModal'
import UpsertExpForm from '../components/UpsertExperienceForm/index.jsx'
import { areFeaturesEnabled } from '../utils/features.js'
import { FEATURES } from '../constants/features.js'
import { PricingModal, usePricing } from '../components/PricingModal/index.js'
import DateTimeRangePopover from '../components/DateTimeRangePopover'
import { useLocalSettings } from '../components/LocalSettings'
import { getSectionsStats } from '../apis/analytics.js'
import { numberFormater, percentageFormatter } from '../utils/formater.js'
import SectionCreate from '../container/Section/Create/index.jsx'
import { useDashboardDetails } from '../hooks/useDashboardDetails.js'

export default function SectionsList() {
  const breakpoints = useBreakpoints()
  const { t } = useTranslation()
  const [activeModal, setActiveModal] = useState(false)
  const [selectedExperienceId, setSelectedExperienceId] = useState(null)
  const toggleModal = (experienceId = null) => {
    setSelectedExperienceId(experienceId)
    setActiveModal((prev) => !prev)
  }
  const [showEnabledSections, setShowEnabledSections] = useState(null)
  const [statusFilter, setStatusFilter] = useState(undefined)
  const [recommendationFilter, setRecommendationFilter] = useState(undefined)
  const [pageFilter, setPageFilter] = useState(undefined)
  const [queryValue, setQueryValue] = useState(undefined)
  const { settings, saveSettings } = useLocalSettings()
  const [selectedDates, setSelectedDates] = useState(
    settings?.durationFilter || {
      start: new Date(new Date() - 7 * 24 * 60 * 60 * 1000),
      end: new Date(),
    },
  )
  const { data: shopData, isLoading: shopDataLoading } = useShopDetails()
  const { data: dashbaordData, isLoading: dashboardLoading } =
    useDashboardDetails()
  const isABExperienceEnabled = areFeaturesEnabled(
    [FEATURES.AB_EXPERIENCE],
    window.shopify.data,
  )

  const sectionIds = shopData?.sections?.map((section) => section.id) ?? []
  const statsQuery = useQuery({
    queryKey: ['sections', sectionIds, selectedDates],
    queryFn: async ({ queryKey }) => {
      const [_, sectionIds, selectedDates] = queryKey
      const payload = {
        dateGte: new Date(selectedDates.start).toISOString().split('T')[0],
        dateLte: new Date(selectedDates.end).toISOString().split('T')[0],
        sections: sectionIds,
      }
      const { data, error } = await getSectionsStats(payload)
      if (error) {
        return Promise.reject(error)
      }
      return data.stats
    },
    enabled: sectionIds.length > 0,
  })
  const sectionWiseStates = statsQuery.data ?? {}
  const pricing = usePricing()

  const handleRecommendationFilterChange = useCallback(
    (value) => setRecommendationFilter(value),
    [],
  )
  const handlePageFilterChange = useCallback(
    (value) => setPageFilter(value),
    [],
  )
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    [],
  )
  const handleStatusFilterRemove = useCallback(
    () => setStatusFilter(undefined),
    [],
  )
  const handleRecommendationFilterRemove = useCallback(
    () => setRecommendationFilter(undefined),
    [],
  )
  const handlePageFilterRemove = useCallback(() => setPageFilter(undefined), [])
  const handleQueryValueRemove = useCallback(() => setQueryValue(undefined), [])
  const handelRemoveShowEnabledSections = useCallback(() => {
    setShowEnabledSections(null)
  }, [])
  const handleFiltersClearAll = useCallback(() => {
    handleStatusFilterRemove()
    handleRecommendationFilterRemove()
    handlePageFilterRemove()
    handleQueryValueRemove()
    handelRemoveShowEnabledSections()
  }, [
    handleStatusFilterRemove,
    handleQueryValueRemove,
    handleRecommendationFilterRemove,
    handlePageFilterRemove,
    handelRemoveShowEnabledSections,
  ])

  const filters = [
    {
      key: 'recommendation',
      label: t('Section.List.Filter.recommendationType'),
      filter: (
        <ChoiceList
          title={t('Section.List.Filter.recommendationType')}
          titleHidden
          choices={Object.values(Recommendations)
            .filter(({ value }) => Boolean(value))
            .map((rec) => ({
              label: t('Widgets.' + rec.value + '.label'),
              value: rec.value,
            }))}
          selected={recommendationFilter || []}
          onChange={handleRecommendationFilterChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'page',
      label: t('Section.List.Filter.page'),
      filter: (
        <ChoiceList
          title={t('Section.List.Filter.page')}
          titleHidden
          choices={[
            ...Object.entries(PAGE_TYPES).map(([key, obj]) => ({
              label: t(`Pages.${obj.value}.label`),
              value: obj.value,
            })),
          ]}
          selected={pageFilter || []}
          onChange={handlePageFilterChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'showEnabledSections',
      label: t('Section.List.enums.showEnabledSection'),
      filter: (
        <Checkbox
          checked={showEnabledSections}
          label={t('Section.List.enums.showEnabledSection')}
          onChange={() =>
            setShowEnabledSections(showEnabledSections ? null : true)
          }
        />
      ),
      pinned: true,
      shortcut: true,
    },
    {
      key: 'status',
      label: t('Section.List.Filter.status'),
      filter: (
        <ChoiceList
          title={t('Section.List.Filter.status')}
          titleHidden
          choices={[
            {
              label: t('Section.List.enums.sectionStatus.enabled'),
              value: true,
            },
            {
              label: t('Section.List.enums.sectionStatus.disabled'),
              value: false,
            },
          ]}
          selected={statusFilter || []}
          onChange={setStatusFilter}
          allowMultiple
        />
      ),
    },
  ]

  const appliedFilters = []

  if (!isEmpty(showEnabledSections)) {
    const key = 'showEnabledSections'
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, showEnabledSections),
      onRemove: handelRemoveShowEnabledSections,
    })
  }

  if (!isEmpty(recommendationFilter)) {
    const key = 'recommendation'
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, recommendationFilter),
      onRemove: handleRecommendationFilterRemove,
    })
  }

  if (!isEmpty(pageFilter)) {
    const key = 'page'
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, pageFilter),
      onRemove: handlePageFilterRemove,
    })
  }

  if (!isEmpty(statusFilter)) {
    const key = 'status'
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, statusFilter),
      onRemove: handleStatusFilterRemove,
    })
  }

  if (shopDataLoading || dashboardLoading) {
    return <Skeleton />
  }

  let experiences = (shopData.experiences || []).map((exp) => ({
    ...exp,
    sections: [],
  }))

  ;(shopData.sections || []).forEach((section) => {
    const experienceId = section.experience
    const idx = experiences.findIndex((exp) => exp.id === experienceId)
    const temp = {
      ...section,
      page: shopData.pages.find((page) => page.id === section.page).type,
    }
    experiences[idx]?.sections?.push(temp)
  })

  const totalExperienceWeights = experiences.reduce((acc, exp) => {
    return acc + exp.weight
  }, 0)

  return (
    <Page
      backAction={{
        content: 'back',
        onAction: () => {
          window.history.back()
        },
      }}
      title={t('Section.List.title')}
      subtitle={t('Section.List.subtitle')}
      primaryAction={{
        content: t('Section.List.cta'),
        onClick: () => toggleModal(experiences[0]?.id),
        icon: PlusIcon,
      }}
      secondaryActions={[
        {
          content: t('Section.List.createExperienceCta'),
          url: isABExperienceEnabled && '/experience/create',
          icon: isABExperienceEnabled ? PlusIcon : LockIcon,
          onAction: () => {
            if (!isABExperienceEnabled) {
              pricing.open({
                features: [FEATURES.AB_EXPERIENCE],
              })
            }
          },
          loading: !isABExperienceEnabled && pricing.isLoading,
        },
        {
          content: t('Section.List.configureRec'),
          url: '/recommendation',
        },
      ]}
    >
      <Box padding={breakpoints.smUp ? '0' : '400'}>
        <Card padding={'0'}>
          <Box padding={'200'}>
            <Text as="h2" variant="headingMd" fontWeight="semibold">
              {t('Section.List.sectionListTitle')}
            </Text>
          </Box>
          {(experiences.length > 1 ||
            experiences?.[0]?.sections?.length > 0) && (
            <Box>
              <div
                style={{
                  padding: 'var(--p-space-200) 0 0 var(--p-space-200)',
                }}
              >
                <InlineGrid columns={'1fr auto'} gap={'200'}>
                  <TextField
                    placeholder={t('Section.List.Filter.queryPlaceholder')}
                    value={queryValue}
                    onChange={handleFiltersQueryChange}
                  />
                  <DateTimeRangePopover
                    handleDurationFilterChange={(duration) => {
                      saveSettings({ ...settings, durationFilter: duration })
                      setSelectedDates(duration)
                    }}
                    durationFilter={selectedDates}
                  />
                </InlineGrid>
              </div>
              <Filters
                filters={filters}
                appliedFilters={appliedFilters}
                onClearAll={handleFiltersClearAll}
                hideQueryField={true}
              />
            </Box>
          )}
          <ExperienceTable
            experiences={experiences.map((exp) => ({
              ...exp,
              visitorPercentage: (
                (exp.weight / totalExperienceWeights) *
                100
              ).toFixed(2),
            }))}
            filters={{
              query: queryValue,
              status: statusFilter,
              recommendation: recommendationFilter,
              page: pageFilter,
              showEnabledSections,
            }}
            anyFiltersApplied={
              !isEmpty(queryValue) ||
              !isEmpty(statusFilter) ||
              !isEmpty(recommendationFilter) ||
              !isEmpty(pageFilter) ||
              showEnabledSections !== null
            }
            sectionStatsArray={sectionWiseStates}
            statsLoading={statsQuery.isLoading}
            activeModal={activeModal}
            toggleModal={toggleModal}
            selectedExperienceId={selectedExperienceId}
          />
        </Card>
      </Box>
      <div
        style={{
          height: '1.5rem',
          width: '100%',
        }}
      ></div>
      <PricingModal modal={pricing} />
      <SectionCreate
        experienceId={selectedExperienceId}
        active={activeModal}
        setActive={setActiveModal}
        dashbaordData={dashbaordData}
        shopData={shopData}
        pricing={pricing}
      />
    </Page>
  )

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'recommendation':
        return (
          'Recommendation: ' +
          value?.map((val) => t(`Widgets.${val}.label`)).join(', ')
        )
      case 'page':
        return (
          'Page: ' + value?.map((val) => t(`Pages.${val}.label`)).join(', ')
        )
      case 'status':
        return (
          'Status: ' +
          value
            ?.map((val) => t(`Section.List.enums.sectionStatus.${val}`))
            .join(', ')
        )
      case 'showEnabledSections':
        return value
          ? t('Section.List.enums.showEnabledSection') + ': True'
          : t('Section.List.enums.showEnabledSection') + ': False'
      default:
        return value
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0
    } else {
      return value === '' || value == null
    }
  }
}

function ExperienceTable({
  experiences,
  filters,
  anyFiltersApplied,
  sectionStatsArray,
  statsLoading,
  activeModal,
  toggleModal,
  selectedExperienceId,
}) {
  const resourceName = {
    singular: 'section',
    plural: 'sections',
  }

  const rowMarkup = experiences.map((experience, index) => (
    <TableRow
      anyFiltersApplied={anyFiltersApplied}
      experience={experience}
      index={index}
      filters={filters}
      key={index}
      sectionStatsArray={sectionStatsArray}
      statsLoading={statsLoading}
      activeModal={activeModal}
      toggleModal={() => toggleModal(experience.id)}
    />
  ))

  if (experiences.length === 0) {
    return (
      <div
        style={{
          height: '30rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        <div
          style={{
            borderRadius: '100%',
            backgroundColor: '#F0F1F2',
            width: 'auto',
            height: 'auto',
          }}
        >
          <img src={onboardingSectionImage} />
        </div>
      </div>
    )
  }

  return (
    <IndexTable
      resourceName={resourceName}
      itemCount={experiences.length}
      selectable={false}
      headings={[]}
      fullWidth
    >
      {rowMarkup}
    </IndexTable>
  )
}

function TableRow({
  experience,
  index,
  filters,
  anyFiltersApplied,
  sectionStatsArray,
  statsLoading,
  activeModal,
  toggleModal,
}) {
  const {
    name: experienceName,
    status: experienceStatus,
    sections: experienceSections,
    id: experienceId,
    visitorPercentage,
    isDefault,
  } = experience
  const [popoverActive, setPopoverActive] = useState(false)
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  )
  const [collapsed, setCollapsed] = useState(false)
  const [isEditting, setEditting] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { redirectToLink } = useRedirect()
  const sections = experienceSections.filter(
    ({ title, type, page, enabled }) => {
      let showSection = true
      if (filters.query) {
        let show = false
        let titleString = title.toLowerCase()
        showSection = titleString.includes(filters.query.toLowerCase())
      }
      if (
        filters.recommendation?.length &&
        !filters.recommendation?.find((val) => val === type)
      ) {
        showSection = false
      }
      if (filters.page?.length && !filters.page?.find((val) => val === page)) {
        showSection = false
      }
      if (filters.status?.length) {
        filters.status = filters.status.map((val) => String(val))
        if (!filters.status.find((val) => val === String(enabled))) {
          showSection = false
        }
      }
      if (filters.showEnabledSections !== null) {
        if (enabled !== filters.showEnabledSections) {
          showSection = false
        }
      }
      return showSection
    },
  )

  const statusChangeHandler = async (status) => {
    if (isDefault) {
      shopify.toast.show(t('Section.List.defaultExpStatusChange'), {
        isError: true,
      })
      return
    }
    setLoading(true)
    const { data, error } = await updateExperience(experienceId, { status })
    if (error) {
      console.log('Error updating experience status', error)
      return
    }
    await queryClient.invalidateQueries(['shopDetails'])
    shopify.toast.show(t('Section.List.expStatusSuccess'))
    setLoading(false)
  }

  const head = (
    <IndexTable.Row disabled>
      <IndexTable.Cell colSpan={6} scope="colgroup" as="th">
        <div style={{ padding: '5px 0' }}>
          <InlineStack align="space-between">
            <InlineGrid
              gap={'400'}
              columns={'auto auto auto'}
              alignItems="center"
            >
              <InlineGrid columns={'auto 1fr'} gap={'200'}>
                <Button
                  variant="monochromePlain"
                  icon={collapsed ? ChevronDownIcon : ChevronUpIcon}
                  onClick={() => {
                    setCollapsed(!collapsed)
                  }}
                />
                <Text as="p" fontWeight="semibold">
                  {experienceName}
                </Text>
              </InlineGrid>
              {isDefault && (
                <Badge size="small">{t('Section.List.expDefault')}</Badge>
              )}
              <Badge
                size="small"
                tone={
                  experienceStatus === ExperienceStatuses.PUBLISHED
                    ? 'success'
                    : 'critical'
                }
              >
                {t(`Section.List.Filter.expStatuses.${experienceStatus}`)}
              </Badge>
            </InlineGrid>
            <Text as="p" tone="subdued" fontWeight="medium">
              {visitorPercentage}% {t('Section.List.Table.subheader.visitors')}
            </Text>
            <Popover
              active={popoverActive}
              activator={
                <Button
                  onClick={togglePopoverActive}
                  icon={MenuVerticalIcon}
                  variant="plain"
                  size="slim"
                />
              }
              onClose={togglePopoverActive}
            >
              <Popover.Pane>
                <div
                  style={{
                    width: 200,
                    padding: 'var(--p-space-200)',
                  }}
                >
                  <BlockStack gap={200}>
                    <Button
                      textAlign="start"
                      icon={StatusIcon}
                      loading={loading}
                      onClick={async () => {
                        await statusChangeHandler(
                          experienceStatus === ExperienceStatuses.PUBLISHED
                            ? ExperienceStatuses.UNPUBLISHED
                            : ExperienceStatuses.PUBLISHED,
                        )
                        togglePopoverActive()
                      }}
                      variant="tertiary"
                    >
                      {t(
                        `Section.List.experienceStatusCTA.${experienceStatus}`,
                      )}
                    </Button>
                    <Button
                      textAlign="start"
                      variant="tertiary"
                      icon={PlusIcon}
                      onClick={() => {
                        toggleModal()
                        setPopoverActive(false)
                      }}
                    >
                      {t('Section.List.Table.emptyState.cta')}
                    </Button>
                    <Button
                      variant="tertiary"
                      textAlign="start"
                      icon={EditIcon}
                      onClick={() => {
                        setEditting(true)
                        togglePopoverActive()
                      }}
                    >
                      {t('Section.List.editExpCta')}
                    </Button>
                    <Button
                      variant="tertiary"
                      textAlign="start"
                      loading={deleting}
                      icon={DeleteIcon}
                      onClick={async () => {
                        if (isDefault) {
                          shopify.toast.show(
                            t('Section.List.defaultExpDelete'),
                            {
                              isError: true,
                            },
                          )
                          return
                        }
                        if (!confirm(t('Section.List.deleteExpConfirm'))) {
                          return
                        }
                        setDeleting(true)
                        const res = await deleteExperience(experienceId)
                        setDeleting(false)
                        if (
                          res.error?.error ===
                          'experience.errors.publishedCanNotBeDeleted'
                        ) {
                          shopify.toast.show(
                            t('Section.List.publishedCanNotBeDeleted'),
                            {
                              isError: true,
                            },
                          )
                          return
                        }
                        await queryClient.invalidateQueries(['shopDetails'])
                        shopify.toast.show(t('Section.List.deleteExpSuccess'))
                        togglePopoverActive()
                      }}
                    >
                      {t('ABExperience.deleteCta')}
                    </Button>
                  </BlockStack>
                </div>
              </Popover.Pane>
            </Popover>
          </InlineStack>
        </div>
      </IndexTable.Cell>
    </IndexTable.Row>
  )

  if (sections.length === 0) {
    return (
      <>
        {head}
        <IndexTable.Row disabled>
          <IndexTable.Cell colSpan={6} scope="colgroup" as="th">
            <Box paddingBlock={'400'}>
              {!anyFiltersApplied && (
                <CreateSection
                  experienceId={experience.id}
                  activeModal={activeModal}
                  toggleModal={toggleModal}
                />
              )}
              {anyFiltersApplied && (
                <InlineStack align="center">
                  <Text as="strong" tone="subdued">
                    {t('Section.NotFound.heading')}
                  </Text>
                </InlineStack>
              )}
            </Box>
          </IndexTable.Cell>
        </IndexTable.Row>
        <EditExpModal
          open={isEditting}
          setOpen={setEditting}
          experience={experience}
        />
      </>
    )
  }

  return (
    <>
      {head}
      {!collapsed && (
        <>
          <IndexTable.Row tone="subdued">
            <IndexTable.Cell as="th">
              {t('Section.List.Table.headers.section')}
            </IndexTable.Cell>
            <IndexTable.Cell as="th">
              {t('Section.List.Table.headers.type')}
            </IndexTable.Cell>
            <IndexTable.Cell as="th">
              {t('Section.List.Table.headers.page')}
            </IndexTable.Cell>
            <IndexTable.Cell as="th">
              {t('Section.List.Table.headers.stats')}
            </IndexTable.Cell>
            <IndexTable.Cell as="th">
              {t('Section.List.Table.headers.status')}
            </IndexTable.Cell>
            <IndexTable.Cell as="th">
              {/* {t('Section.List.Table.headers.actions')} */}
            </IndexTable.Cell>
          </IndexTable.Row>
          {sections.map((section) => (
            <SectionOption section={section} />
          ))}
        </>
      )}
      <EditExpModal
        open={isEditting}
        setOpen={setEditting}
        experience={experience}
      />
    </>
  )

  function SectionOption({ section }) {
    const { id, title, type, page, enabled } = section
    const [copied, setCopied] = useState(false)
    return (
      <IndexTable.Row
        id={id}
        key={id}
        position={index}
        onClick={() =>
          redirectToLink({
            url: '/sections/' + id,
            external: false,
          })
        }
      >
        <IndexTable.Cell>
          <div
            style={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              margin: '5px 0',
            }}
          >
            <div style={{ minWidth: '2rem' }}>
              <Avatar
                customer
                size="sm"
                // name={name}
              />
            </div>
            <div>
              <Text as="h3" variant="bodyMd" fontWeight="bold">
                {title}
              </Text>
              <InlineStack align="left" gap="100">
                <Text tone="base">ID: {id}</Text>
                <Tooltip
                  content={
                    <span>
                      {copied
                        ? t(
                            'Section.Details.DesignDetails.CustomAlignment.doneCta',
                          )
                        : t(
                            'Section.Details.DesignDetails.CustomAlignment.cta',
                          )}
                    </span>
                  }
                  preferredPosition="above"
                >
                  <span
                    onClick={(e) => {
                      e.stopPropagation()
                      navigator.clipboard.writeText(id)
                      setCopied(true)
                      setTimeout(() => setCopied(false), 1000)
                    }}
                  >
                    <Icon
                      source={copied ? ClipboardCheckFilledIcon : ClipboardIcon}
                      tone="base"
                    />
                  </span>
                </Tooltip>
              </InlineStack>
            </div>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>{t(`Widgets.${type}.label`)}</IndexTable.Cell>
        <IndexTable.Cell>{t(`Pages.${page}.label`)}</IndexTable.Cell>
        <IndexTable.Cell>
          <InlineStack gap="300">
            <InlineStack gap="100">
              <Text>CTR:</Text>
              <Text fontWeight="semibold">
                {statsLoading ? (
                  <span
                    style={{
                      display: 'block',
                      width: '50px',
                    }}
                  >
                    <SkeletonDisplayText maxWidth="50px" size="small" />
                  </span>
                ) : (
                  percentageFormatter(
                    sectionStatsArray.find((stat) => stat.section == id)
                      ?.interactionRate ?? 0,
                  )
                )}
              </Text>
            </InlineStack>
          </InlineStack>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Badge progress={'complete'} tone={enabled ? 'success' : 'critical'}>
            {enabled
              ? t('Section.List.enums.sectionStatus.enabled')
              : t(`Section.List.enums.sectionStatus.disabled`)}
          </Badge>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  }
}

function CreateSection({ experienceId, activeModal, toggleModal }) {
  const { t } = useTranslation()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Text variant="headingMd" as="h6" tone="base">
        {t('Section.List.Table.emptyState.title')}
      </Text>
      <Text variant="bodySm" as="p">
        {t('Section.List.Table.emptyState.subtitle')}
      </Text>
      <Button variant="primary" onClick={() => toggleModal(experienceId)}>
        {t('Section.List.Table.emptyState.cta')}
      </Button>
    </div>
  )
}

function EditExpModal({ experience, open, setOpen }) {
  const { t } = useTranslation()

  const submitHandler = async (val) => {
    const res = await updateExperience(experience.id, {
      name: val.name,
      weight: parseInt(val.weightage),
      startTime: val.startTime,
      endTime: val.endTime,
      triggerKey: Boolean(val.key) ? val.key : null,
      triggerValue: Boolean(val.value) ? val.value : null,
    })
    setOpen(false)
    if (res.error) {
      return {
        error: res.error,
      }
    }
    await queryClient.invalidateQueries(['shopDetails'])
    shopify.toast.show(t('Section.List.expStatusSuccess'))
    return { error: null }
  }

  return (
    <CustomModal
      show={open}
      setShow={setOpen}
      title={t('ABExperience.editTitle')}
      variant="medium"
      maxHeight={'max-content'}
    >
      <div
        style={{
          width: '500px',
        }}
      >
        <Card>
          <UpsertExpForm
            submitHandler={submitHandler}
            currentValues={{
              name: experience.name,
              weight: experience.weight,
              startTime: experience.startTime,
              endTime: experience.endTime,
              triggerKey: experience.triggerKey,
              triggerValue: experience.triggerValue,
            }}
          />
        </Card>
      </div>
    </CustomModal>
  )
}

function Skeleton() {
  const { t } = useTranslation()
  return (
    <SkeletonPage primaryAction title={t('Section.List.title')}>
      <Layout>
        <Layout.Section>
          <Card>
            <BlockStack gap={'400'}>
              <SkeletonDisplayText maxWidth="100%" size="small" />
              <SkeletonBodyText lines={4} />
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}
