import {
  Badge,
  BlockStack,
  Button,
  Checkbox,
  DataTable,
  Divider,
  ExceptionList,
  FormLayout,
  InlineStack,
  Label,
  Select,
  Text,
  Tooltip,
} from '@shopify/polaris'
import { InfoIcon } from '@shopify/polaris-icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Pages from '../../../constants/page'
import * as WidgetsList from '../../../constants/widgets'
import { useRedirect } from '../../../hooks'
import { useSectionCreateForm } from './hooks'
import { createSection } from '../../../apis/section'
import PageSelector from '../../../components/PageSelector'
import { createPage } from '../../../apis/page'
import CustomModal from '../../../components/CustomModal'
import queryClient from '../../../utils/query'

import './style.css'

const UPGRADE_ICON = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M7.70759 2.17732C7.73637 2.12506 7.77864 2.08148 7.83 2.05113C7.88136 2.02077 7.93993 2.00476 7.99959 2.00476C8.05925 2.00476 8.11782 2.02077 8.16918 2.05113C8.22054 2.08148 8.26282 2.12506 8.29159 2.17732L10.2596 5.91332C10.3065 5.99983 10.372 6.07488 10.4514 6.13307C10.5308 6.19126 10.622 6.23116 10.7187 6.2499C10.8153 6.26863 10.9148 6.26575 11.0102 6.24144C11.1056 6.21714 11.1944 6.17202 11.2703 6.10932L14.1216 3.66666C14.1763 3.62214 14.2438 3.59614 14.3142 3.59239C14.3847 3.58865 14.4545 3.60735 14.5137 3.64582C14.5728 3.68429 14.6182 3.74053 14.6434 3.80645C14.6685 3.87237 14.6721 3.94457 14.6536 4.01266L12.7643 10.8433C12.7257 10.9831 12.6426 11.1065 12.5276 11.1948C12.4126 11.2831 12.2719 11.3315 12.1269 11.3327H3.87292C3.72781 11.3316 3.587 11.2833 3.47185 11.195C3.35671 11.1067 3.27352 10.9832 3.23492 10.8433L1.34626 4.01332C1.32776 3.94524 1.33134 3.87304 1.35649 3.80712C1.38163 3.7412 1.42705 3.68495 1.48619 3.64649C1.54534 3.60802 1.61516 3.58931 1.68562 3.59306C1.75607 3.5968 1.82352 3.6228 1.87826 3.66732L4.72892 6.10999C4.80479 6.17268 4.8936 6.2178 4.98897 6.24211C5.08434 6.26641 5.18391 6.2693 5.28052 6.25056C5.37714 6.23183 5.46841 6.19193 5.54779 6.13374C5.62716 6.07554 5.69266 6.0005 5.73959 5.91399L7.70759 2.17732Z"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33289 14H12.6662"
      stroke="currentColor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function SectionCreate({
  experienceId = null,
  active,
  setActive,
  dashbaordData,
  shopData,
  pricing
}) {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const { redirectToLink } = useRedirect()
  const form = useSectionCreateForm({
    currentValues: {
      experience: experienceId,
    },
    onSubmit: async (values) => {
      console.info('Creating section with values', values)
      let page = shopData.pages.find((page) => page.type === values.page)
      if (!page) {
        const { data: createdPageResponse, error } = await createPage({
          type: values.page,
        })
        if (error) {
          console.log('Error creating page', error)
          return {
            status: 'fail',
          }
        }
        page = createdPageResponse
      }
      const res = await createSection({
        type: values.recommendations,
        page: page.id,
        experience: values.experience,
        title: values.name,
      })
      if (res.error) {
        console.log('Error creating section', res.error)
        return {
          status: 'fail',
        }
      }
      await queryClient.invalidateQueries(['shopDetails'])
      redirectToLink({
        external: false,
        url: `/sections/${res.data.section.id}/edit?showSave=1&backUrl=/sections`,
      })
      console.info('Section created successfully with id', res.data.section.id)
      return {
        status: 'success',
      }
    },
  })

  return (
    <>
      <CustomModal
        show={active}
        setShow={setActive}
        variant="medium"
        height={'68vh'}
        maxHeight={'max-content'}
        maxWidth={'700px'}
        title={t('Section.Create.title')}
        onSave={form.submit}
        saveButtonCta={t('Section.Create.Form.buttonText')}
        subFooterComponent={
          <ExceptionList
            items={[
              {
                icon: InfoIcon,
                description: t('Section.Create.Form.infoText'),
              },
            ]}
          />
        }
        saveButtonProps={{
          loading: form.submitting,
          disabled: keepCreateButtonDisabled(),
        }}
      >
        <FormLayout>
          <div
            style={{
              padding:
                'var(--p-space-300) var(--p-space-300) 0 var(--p-space-300)',
            }}
          >
            <FormLayout.Group>
              <PageSelector
                options={getShopifyPageList()}
                label={t('Section.Create.Form.Page.title')}
                placeholder={t('Section.Create.Form.Page.placeholder')}
                value={form.fields.page.value}
                onChange={form.fields.page.onChange}
                error={form.fields.page.allErrors?.[0]}
                shopPlan={dashbaordData.shopPlan}
              />
              <Select
                {...form.fields.experience}
                value={form.fields.experience.value?.toString()}
                onChange={(value) =>
                  form.fields.experience.onChange(parseInt(value))
                }
                options={getShopExperienceList()}
                label={t('Section.Create.Form.Experience.title')}
              />
            </FormLayout.Group>
          </div>
          <FormLayout>
            <BlockStack gap={'300'}>
              <div
                style={{
                  paddingLeft: 'var(--p-space-300)',
                  paddingRight: 'var(--p-space-300)',
                }}
              >
                <Label>{t('Section.Create.Form.Recommendation.title')}</Label>
              </div>
              <BlockStack>
                <Divider />
                <DataTable
                  footerContent={
                    <InlineStack align="start">
                      <Button
                        onClick={() => setExpanded(!expanded)}
                        variant="plain"
                        disclosure={expanded ? 'up' : 'down'}
                      >
                        {expanded ? 'Show Less' : 'Show More'}
                      </Button>
                    </InlineStack>
                  }
                  columnContentTypes={[
                    'text',
                    'numeric',
                    'numeric',
                    'numeric',
                    'numeric',
                  ]}
                  headings={[]}
                  rows={getWidgetTypes()
                    .map((recommendation) => {
                      const widget = Object.values(WidgetsList).find(
                        ({ value }) => value === recommendation.value,
                      )
                      const canCreateOnSelectedPage = canCreateMoreWidgets(
                        recommendation.value,
                        form.fields.page.value,
                        shopData.sections
                          .filter(
                            (section) => section.experience == experienceId,
                          )
                          .map((section) => ({
                            ...section,
                            page: shopData.pages.find(
                              (page) => page.id === section.page,
                            ).type,
                          })),
                      )
                      const isAvailableOnCurrentPage = Boolean(
                        widget.allowedOnPages.find(
                          ({ value }) => value === form.fields.page.value,
                        ),
                      )
                      const isRecommended = Boolean(
                        widget.recommendedPages.find(
                          ({ value }) => value === form.fields.page.value,
                        ),
                      )
                      const { value, icon } = recommendation
                      const isPageDeprecated = getShopifyPageList().find(
                        (page) => page.value === form.fields.page.value,
                      )?.deprecated
                      let toolTipText = ''
                      const recommendationEnabled =
                        dashbaordData.shopPlan.recommendationsEnabled.find(
                          (value) => value === recommendation.value,
                        )
                      if (!form.fields.page.value) {
                        toolTipText = t('Widgets.ToolTip.selectPageText')
                      } else if (!recommendationEnabled) {
                        toolTipText = t('Widgets.ToolTip.widgetNotEnabled')
                      } else if (!isAvailableOnCurrentPage) {
                        toolTipText = t(
                          'Widgets.ToolTip.notAvailableOnPageText',
                        )
                      } else if (!canCreateOnSelectedPage) {
                        toolTipText = t(
                          'Section.Create.maximumWidgetLimitReached',
                        )
                      } else if (
                        isRecommended &&
                        widget.recommendedPageDescriptions[
                          form.fields.page.value
                        ]
                      ) {
                        toolTipText = t(
                          `Widgets.${value}.page.${form.fields.page.value}`,
                        )
                      } else {
                        toolTipText = t(`Widgets.${value}.description`)
                      }
                      const isEnabled =
                        isAvailableOnCurrentPage &&
                        recommendationEnabled &&
                        canCreateOnSelectedPage &&
                        !isPageDeprecated
                      const needsUpgrade = !recommendationEnabled
                      const isNotEnabled =
                        !isAvailableOnCurrentPage || isPageDeprecated
                      const status = isEnabled
                        ? SECTION_STATUS.ENABLED
                        : needsUpgrade
                          ? SECTION_STATUS.UPGRADE
                          : isNotEnabled
                            ? SECTION_STATUS.NOTENABLED
                            : SECTION_STATUS.DEPRECATED
                      const recommendationCard = [
                        <RecommendationTypeCard
                          toolTipText={!isEnabled ? toolTipText : null}
                          disabled={
                            !isAvailableOnCurrentPage ||
                            !recommendationEnabled ||
                            !canCreateOnSelectedPage ||
                            isPageDeprecated
                          }
                          upgradeHandler={() =>
                            pricing.open({
                              recommendation: recommendation.value,
                              currentPlan: dashbaordData.shopPlan,
                            })
                          }
                          upgradeRequired={!recommendationEnabled}
                          isRecommended={isRecommended}
                          key={recommendation.value}
                          value={value}
                          icon={icon}
                          title={t(`Widgets.${value}.label`)}
                          subtitle={t(`Widgets.${value}.description`)}
                          checked={
                            form.fields.recommendations.value ===
                            recommendation.value
                          }
                          onClick={(val) => {
                            form.fields.recommendations.onChange(
                              recommendation.value,
                            )
                          }}
                        />,
                      ]
                      return { recommendationCard, status }
                    })
                    .sort((a, b) => {
                      const order = [
                        SECTION_STATUS.ENABLED,
                        SECTION_STATUS.UPGRADE,
                        SECTION_STATUS.NOTENABLED,
                        SECTION_STATUS.DEPRECATED,
                      ]
                      return order.indexOf(a.status) - order.indexOf(b.status)
                    })
                    .slice(0, expanded ? undefined : 5)
                    .map((sortingObject) => sortingObject.recommendationCard)}
                />
              </BlockStack>
            </BlockStack>
          </FormLayout>
        </FormLayout>
      </CustomModal>
    </>
  )

  function getShopifyPageList() {
    return Object.values(Pages)
      .filter(({ value }) => Boolean(value))
      .sort((a, b) => a.score - b.score)
      .map((page) => ({
        value: page.value,
        label: page.label,
        upgradeRequired: !dashbaordData.shopPlan.pagesEnabled.includes(
          page.value,
        ),
        shopifyPlusOnly: page?.shopifyPlusOnly ?? false,
        deprecated: page.deprecated,
      }))
  }

  function getShopExperienceList() {
    const { experiences } = shopData
    return (
      experiences.map(({ name, id }) => ({
        label: name,
        value: id.toString(),
      })) ?? []
    )
  }

  function keepCreateButtonDisabled() {
    return (
      !form.fields.page.value ||
      !form.fields.experience.value ||
      !form.fields.recommendations.value
    )
  }

  function getWidgetTypes() {
    const recommendationsEnabled = dashbaordData.shopPlan.recommendationsEnabled
    const widgetTypes = Object.values(WidgetsList)
      .filter((w) => Boolean(w.value))
      .sort((a, b) => {
        const isFirstAvailable =
          a.allowedOnPages?.find(
            ({ value }) => value === form.fields.page.value,
          ) && recommendationsEnabled.find((value) => value === a.value)
        const isSecondAvailable =
          b.allowedOnPages?.find(
            ({ value }) => value === form.fields.page.value,
          ) && recommendationsEnabled.find((value) => value === a.value)
        if (isFirstAvailable && isSecondAvailable) return a.score - b.score
        if (isFirstAvailable) return -10
        if (isSecondAvailable) return 10
        return -20
      })
      .map((widget) => ({
        value: widget.value,
        title: widget.label,
        subtitle: widget.description,
        icon: widget.icon,
      }))

    return widgetTypes
  }
}

const RecommendationTypeCard = ({
  toolTipText,
  title,
  subtitle,
  checked,
  onClick,
  icon,
  disabled = false,
  inBeta = false,
  isRecommended = false,
  upgradeRequired = false,
  upgradeHandler,
}) => {
  const [loading, setLoading] = useState(false)

  const child = (
    <div
      style={{
        width: '98%',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          opacity: disabled ? 0.75 : 1,
          width: '100%',
          position: 'relative',
        }}
      >
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={onClick}
          label={
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: disabled ? '85%' : '100%',
                }}
              >
                <img src={icon} width={32} height={32} alt="icon" />
                <div
                  style={{
                    display: 'flex',
                    flex: '1 1 0%',
                    paddingLeft: 'var(--p-space-200)',
                  }}
                >
                  <BlockStack>
                    <InlineStack gap={'200'}>
                      <Text as="h6" fontWeight="semibold">
                        {title}
                      </Text>
                      {inBeta && <Badge tone="warning">Beta</Badge>}
                      {isRecommended && (
                        <Badge tone="attention">Recommended</Badge>
                      )}
                    </InlineStack>
                    <Text as="p">{subtitle}</Text>
                  </BlockStack>
                </div>
              </div>
            </>
          }
        />
        {upgradeRequired && (
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={async () => {
                setLoading(true)
                await upgradeHandler()
                setLoading(false)
              }}
              icon={UPGRADE_ICON}
              variant="plain"
            >
              Upgrade
            </Button>
          </div>
        )}
      </div>
    </div>
  )

  if (toolTipText) return <Tooltip content={toolTipText}>{child}</Tooltip>

  return child
}

function canCreateMoreWidgets(widgetType, pageType, widgets) {
  if (!pageType || !widgetType) return false

  let widgetList = widgets.filter(
    (widget) => widget.type === widgetType && widget.page === pageType,
  )

  const WIDGET = Object.values(WidgetsList)
    .filter((w) => Boolean(w?.value))
    .find(({ value }) => value === widgetType)

  const maxAllowed = WIDGET.maxAllowed[pageType] ?? WIDGET.maxAllowed.default

  return widgetList.length < maxAllowed
}

export const SECTION_STATUS = {
  ENABLED: 'enabled',
  UPGRADE: 'needs upgrade',
  NOTENABLED: 'not enabled',
  DEPRECATED: 'deprecated',
}
