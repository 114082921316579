import humps from 'humps'

import { BULK_UPLOAD_STATUS } from '../constants/bulkUpload'
import * as WIDGETS from '../constants/widgets'
import getApiClient from '.'

export async function getProductRecommendationList({ widgetTypes, products }) {
  try {
    const client = await getApiClient()
    const res = await client.post('/api/recommendations/product', {
      recommendationTypes: widgetTypes,
      productIds: products,
    })
    return {
      error: null,
      data: res.data,
    }
  } catch (error) {
    return {
      error: error,
      data: null,
    }
  }
}

export async function getProductRecommendationDetails({
  productId,
  widgetTypes,
}) {
  const res = await getProductRecommendationList({
    widgetTypes: widgetTypes,
    products: [productId],
  })
  if (res.error) {
    return {
      error: res.error,
      data: null,
    }
  }
  return {
    error: null,
    data: res.data.recommendations[0],
  }
}

export async function updateProductRecommendations({
  widgetType,
  recommendationIds,
  product,
  reverseProducts = []
}) {
  try {
    const client = await getApiClient()
    const res = await client.post('/api/recommendations/product/update', {
      product,
      recommendedProducts: recommendationIds,
      recommendationType: widgetType,
      reverseProducts
    })
    return {
      error: null,
      data: res.data,
    }
  } catch (error) {
    return {
      error: error,
      data: null,
    }
  }
}

export async function getRules({ recommendationType, section }) {
  try {
    const client = await getApiClient()
    const res = await client.post('/api/recommendations/rule', {
      recommendationType,
      section,
    })
    return {
      error: null,
      data: res.data,
    }
  } catch (e) {
    return {
      error: e.response.data,
      data: null,
    }
  }
}

export async function createRule(data) {
  try {
    const client = await getApiClient()
    const res = await client.post('/api/recommendations/rule/create', data)
    return {
      error: null,
      data: res.data,
    }
  } catch (e) {
    return {
      error: e.response.data,
      data: null,
    }
  }
}

export async function deleteRule(ruleId) {
  try {
    const client = await getApiClient()
    const res = await client.delete(`/api/recommendations/rule/${ruleId}`)
    return {
      error: null,
      data: res.data,
    }
  } catch (e) {
    return {
      error: e.response.data,
      data: null,
    }
  }
}

export async function updateRule(ruleId, data) {
  try {
    const client = await getApiClient()
    const res = await client.post(`/api/recommendations/rule/${ruleId}`, data)
    return {
      error: null,
      data: res.data,
    }
  } catch (e) {
    return {
      error: e.response.data,
      data: null,
    }
  }
}

export async function triggerBulkUpload(formData) {
  try {
    const client = await getApiClient()
    const res = await client.post(
      '/api/recommendations/bulkupload/trigger',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return {
      error: null,
      data: res.data,
    }
  } catch (e) {
    return {
      error: e.response.data,
      data: null,
    }
  }
}

export async function getBulkUpload(data) {
  const client = await getApiClient()
  try {
    const res = await client.get('/api/recommendations/bulkupload')
    return {
      error: null,
      data: res.data.recommendationUploads.map((upload) => ({
        id: upload.id,
        name: upload.name,
        recommendationType: upload.recommendationType,
        rowCount: `${upload.validLines}/${upload.totalLines} ${
          upload.status === BULK_UPLOAD_STATUS.SUCCESS
            ? 'Success'
            : upload.status === BULK_UPLOAD_STATUS.INPROGRESS
              ? 'In Progress'
              : 'Fail'
        }`,
        createdAt: upload.createdAt,
        updatedAt: upload.updatedAt,
        status: upload.status,
      })),
    }
  } catch (e) {
    return {
      error: e.response.data,
      data: null,
    }
  }
}

export function getDatasetDetails(data) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        name: 'Product 11',
        recommendationType: WIDGETS.CROSS_SELL.value,
        rowCount: '0/12 Fail',
        createdAt: '2024-04-16T05:43:13Z',
        updatedAt: '2024-04-16T05:43:13Z',
        status: 'failed',
        id: 11,
        products: [
          {
            parent: {
              title: '3/4 sleev Kimoono Dress',
              id: '464',
              handle: '123',
              price: '$12',
            },
            recommendation: {
              title: '3/4 sleev Kimoono Dress',
              id: '6344',
              handle: '123',
              price: '$12',
            },
            status: 'failed',
          },
          {
            parent: {
              title: '3/4 sleev Kimoono Dress',
              id: '234',
              handle: '123',
              price: '$12',
            },
            recommendation: {
              title: '3/4 sleev Kimoono Dress',
              id: '78',
              handle: '123',
              price: '$12',
            },
            status: 'failed',
          },
          {
            parent: {
              title: '3/4 sleev Kimoono Dress',
              id: '546',
              handle: '123',
              price: '$12',
            },
            recommendation: {
              title: '3/4 sleev Kimoono Dress',
              id: '645',
              handle: '123',
              price: '$12',
            },
            status: 'success',
          },
          {
            parent: {
              title: '3/4 sleev Kimoono Dress',
              id: '432',
              handle: '123',
              price: '$12',
            },
            recommendation: {
              title: '3/4 sleev Kimoono Dress',
              id: '764',
              handle: '123',
              price: '$12',
            },
            status: 'success',
          },
          {
            parent: {
              title: '3/4 sleev Kimoono Dress',
              id: '453',
              handle: '123',
              price: '$12',
            },
            recommendation: {
              title: '3/4 sleev Kimoono Dress',
              id: '098',
              handle: '123',
              price: '$12',
            },
            status: 'success',
          },
          {
            parent: {
              title: '3/4 sleev Kimoono Dress',
              id: '6557',
              handle: '123',
              price: '$12',
            },
            recommendation: {
              title: '3/4 sleev Kimoono Dress',
              id: '45',
              handle: '123',
              price: '$12',
            },
            status: 'success',
          },
        ],
      })
    }, 300)
  })
}

export async function getGlobalRecommendations(recommendationType) {
  const client = await getApiClient()
  try {
    const res = await client.get(
      '/api/recommendations/topproduct/' + recommendationType,
    )
    return {
      error: null,
      data: res.data.products,
    }
  } catch (e) {
    return {
      error: e.response.data,
      data: null,
    }
  }
}

export async function updateGlobalRecommendations(
  recommendationType,
  recommendations,
) {
  try {
    const client = await getApiClient()
    const res = await client.post(
      '/api/recommendations/topproduct/' + recommendationType,
      {
        recommendations: recommendations,
      },
    )
    return {
      error: null,
      data: res.data,
    }
  } catch (e) {
    return {
      error: e.response.data,
      data: null,
    }
  }
}

export async function getAutoRecommendations(data) {
  try {
    const client = await getApiClient()
    const res = await client.post('/api/recommendations/autorec', data)
    return {
      error: null,
      data: res.data,
    }
  } catch (e) {
    return {
      error: e.response.data,
      data: null,
    }
  }
}

export async function upsertAutoRecommendations(data) {
  try {
    const client = await getApiClient()
    const res = await client.post('/api/recommendations/autorec/upsert', data)
    return {
      error: null,
      data: res.data,
    }
  } catch (e) {
    return {
      error: e.response.data,
      data: null,
    }
  }
}

export async function deleteAutoRecommendation(data) {
  try {
    const client = await getApiClient()
    const res = await client.post('/api/recommendations/autorec/delete', data)
    return {
      error: null,
      data: res.data,
    }
  } catch (e) {
    return {
      error: e.response.data,
      data: null,
    }
  }
}
